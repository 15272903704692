/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:6bdb2626-cc9b-4ae7-a769-3547ce98826f",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_2ctfWkfo0",
    "aws_user_pools_web_client_id": "7208lftcj1uep8no56bg4hfcep",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://yeqbfvq2l5c3bntuu4nhiwqwqy.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-iw4srgapbbfptgjvhud5za5vye"
};


export default awsmobile;
