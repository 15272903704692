/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBlog = /* GraphQL */ `
  query GetBlog($id: ID!) {
    getBlog(id: $id) {
      id
      name
      createdAt
      updatedAt
      posts {
        items {
          id
          title
          blogID
          tags
          createdAt
          updatedAt
          blog {
            id
            name
            createdAt
            updatedAt
            posts {
              nextToken
            }
          }
          comments {
            items {
              id
              postID
              content
              createdAt
              updatedAt
            }
            nextToken
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const listBlogs = /* GraphQL */ `
  query ListBlogs(
    $filter: ModelBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        posts {
          items {
            id
            title
            blogID
            tags
            createdAt
            updatedAt
            blog {
              id
              name
              createdAt
              updatedAt
            }
            comments {
              nextToken
            }
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      postID
      content
      createdAt
      updatedAt
      post {
        id
        title
        blogID
        tags
        createdAt
        updatedAt
        blog {
          id
          name
          createdAt
          updatedAt
          posts {
            items {
              id
              title
              blogID
              tags
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
        comments {
          items {
            id
            postID
            content
            createdAt
            updatedAt
            post {
              id
              title
              blogID
              tags
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        owner
      }
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postID
        content
        createdAt
        updatedAt
        post {
          id
          title
          blogID
          tags
          createdAt
          updatedAt
          blog {
            id
            name
            createdAt
            updatedAt
            posts {
              nextToken
            }
          }
          comments {
            items {
              id
              postID
              content
              createdAt
              updatedAt
            }
            nextToken
          }
          owner
        }
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      customerID
      customerName
      streetAddress
      city
      state
      zip
      phoneNumber
      location {
        lat
        lon
      }
      delType
      lastInv
      lastInvAmt
      unitsDelATLastSale
      driverunitinvATLastSale
      boxCapInventoriedATLastSale
      daysSinceLastSale
      unitsScannedSinceLastDel
      boxCapScannedSinceLastSale
      lastScan
      sevenLbUnitCap
      maintainBB
      sBT
      stopComments
      nextRoutedExpRun
      daysTillNextSchedDel
      nextScheduledDeliveryBlueTick
      nextDeliveryMauveTick
      ableToSupportBackstock
      backstockHasbeenapproved
      approvedBackstockUnits
      distFacility
      seasonalDeliveries
      straighLineMiles
      commissionRingCode
      commissionRingStatus
      nOfPRsAccountIsOn
      primaryRoute
      routeProfile
      hCIBoxes
      boxCapCF
      nOfBoxPics
      lastBoxPic
      lastBoxPrevMaint
      lastCondCleaning
      nextWorkOrderDue
      adjNormalPPUnitSales
      adjNormalPPUnits
      ppTurnRatio
      currentTurn
      cYUnits
      cYSales
      pYSales
      cYPYdiff
      pastYearInvoiceCount
      yearWddVsNormal
      adjustmentPercentage
      adjustedNormalPPUnits
      callins {
        items {
          id
          customer {
            id
            customerID
            customerName
            streetAddress
            city
            state
            zip
            phoneNumber
            location {
              lat
              lon
            }
            delType
            lastInv
            lastInvAmt
            unitsDelATLastSale
            driverunitinvATLastSale
            boxCapInventoriedATLastSale
            daysSinceLastSale
            unitsScannedSinceLastDel
            boxCapScannedSinceLastSale
            lastScan
            sevenLbUnitCap
            maintainBB
            sBT
            stopComments
            nextRoutedExpRun
            daysTillNextSchedDel
            nextScheduledDeliveryBlueTick
            nextDeliveryMauveTick
            ableToSupportBackstock
            backstockHasbeenapproved
            approvedBackstockUnits
            distFacility
            seasonalDeliveries
            straighLineMiles
            commissionRingCode
            commissionRingStatus
            nOfPRsAccountIsOn
            primaryRoute
            routeProfile
            hCIBoxes
            boxCapCF
            nOfBoxPics
            lastBoxPic
            lastBoxPrevMaint
            lastCondCleaning
            nextWorkOrderDue
            adjNormalPPUnitSales
            adjNormalPPUnits
            ppTurnRatio
            currentTurn
            cYUnits
            cYSales
            pYSales
            cYPYdiff
            pastYearInvoiceCount
            yearWddVsNormal
            adjustmentPercentage
            adjustedNormalPPUnits
            callins {
              nextToken
            }
            createdAt
            updatedAt
          }
          customerID
          status
          reason
          notes
          needsDelivery
          boxDown
          asap
          completed
          createdAt
          deliveryDate
          driver {
            id
            firstName
            lastName
            callins {
              nextToken
            }
            createdAt
            updatedAt
          }
          driverID
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        customerName
        streetAddress
        city
        state
        zip
        phoneNumber
        location {
          lat
          lon
        }
        delType
        lastInv
        lastInvAmt
        unitsDelATLastSale
        driverunitinvATLastSale
        boxCapInventoriedATLastSale
        daysSinceLastSale
        unitsScannedSinceLastDel
        boxCapScannedSinceLastSale
        lastScan
        sevenLbUnitCap
        maintainBB
        sBT
        stopComments
        nextRoutedExpRun
        daysTillNextSchedDel
        nextScheduledDeliveryBlueTick
        nextDeliveryMauveTick
        ableToSupportBackstock
        backstockHasbeenapproved
        approvedBackstockUnits
        distFacility
        seasonalDeliveries
        straighLineMiles
        commissionRingCode
        commissionRingStatus
        nOfPRsAccountIsOn
        primaryRoute
        routeProfile
        hCIBoxes
        boxCapCF
        nOfBoxPics
        lastBoxPic
        lastBoxPrevMaint
        lastCondCleaning
        nextWorkOrderDue
        adjNormalPPUnitSales
        adjNormalPPUnits
        ppTurnRatio
        currentTurn
        cYUnits
        cYSales
        pYSales
        cYPYdiff
        pastYearInvoiceCount
        yearWddVsNormal
        adjustmentPercentage
        adjustedNormalPPUnits
        callins {
          items {
            id
            customer {
              id
              customerID
              customerName
              streetAddress
              city
              state
              zip
              phoneNumber
              delType
              lastInv
              lastInvAmt
              unitsDelATLastSale
              driverunitinvATLastSale
              boxCapInventoriedATLastSale
              daysSinceLastSale
              unitsScannedSinceLastDel
              boxCapScannedSinceLastSale
              lastScan
              sevenLbUnitCap
              maintainBB
              sBT
              stopComments
              nextRoutedExpRun
              daysTillNextSchedDel
              nextScheduledDeliveryBlueTick
              nextDeliveryMauveTick
              ableToSupportBackstock
              backstockHasbeenapproved
              approvedBackstockUnits
              distFacility
              seasonalDeliveries
              straighLineMiles
              commissionRingCode
              commissionRingStatus
              nOfPRsAccountIsOn
              primaryRoute
              routeProfile
              hCIBoxes
              boxCapCF
              nOfBoxPics
              lastBoxPic
              lastBoxPrevMaint
              lastCondCleaning
              nextWorkOrderDue
              adjNormalPPUnitSales
              adjNormalPPUnits
              ppTurnRatio
              currentTurn
              cYUnits
              cYSales
              pYSales
              cYPYdiff
              pastYearInvoiceCount
              yearWddVsNormal
              adjustmentPercentage
              adjustedNormalPPUnits
              createdAt
              updatedAt
            }
            customerID
            status
            reason
            notes
            needsDelivery
            boxDown
            asap
            completed
            createdAt
            deliveryDate
            driver {
              id
              firstName
              lastName
              createdAt
              updatedAt
            }
            driverID
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCallin = /* GraphQL */ `
  query GetCallin($deliveryDate: AWSDate!, $status: Status!, $reason: Reason!) {
    getCallin(deliveryDate: $deliveryDate, status: $status, reason: $reason) {
      id
      customer {
        id
        customerID
        customerName
        streetAddress
        city
        state
        zip
        phoneNumber
        location {
          lat
          lon
        }
        delType
        lastInv
        lastInvAmt
        unitsDelATLastSale
        driverunitinvATLastSale
        boxCapInventoriedATLastSale
        daysSinceLastSale
        unitsScannedSinceLastDel
        boxCapScannedSinceLastSale
        lastScan
        sevenLbUnitCap
        maintainBB
        sBT
        stopComments
        nextRoutedExpRun
        daysTillNextSchedDel
        nextScheduledDeliveryBlueTick
        nextDeliveryMauveTick
        ableToSupportBackstock
        backstockHasbeenapproved
        approvedBackstockUnits
        distFacility
        seasonalDeliveries
        straighLineMiles
        commissionRingCode
        commissionRingStatus
        nOfPRsAccountIsOn
        primaryRoute
        routeProfile
        hCIBoxes
        boxCapCF
        nOfBoxPics
        lastBoxPic
        lastBoxPrevMaint
        lastCondCleaning
        nextWorkOrderDue
        adjNormalPPUnitSales
        adjNormalPPUnits
        ppTurnRatio
        currentTurn
        cYUnits
        cYSales
        pYSales
        cYPYdiff
        pastYearInvoiceCount
        yearWddVsNormal
        adjustmentPercentage
        adjustedNormalPPUnits
        callins {
          items {
            id
            customer {
              id
              customerID
              customerName
              streetAddress
              city
              state
              zip
              phoneNumber
              delType
              lastInv
              lastInvAmt
              unitsDelATLastSale
              driverunitinvATLastSale
              boxCapInventoriedATLastSale
              daysSinceLastSale
              unitsScannedSinceLastDel
              boxCapScannedSinceLastSale
              lastScan
              sevenLbUnitCap
              maintainBB
              sBT
              stopComments
              nextRoutedExpRun
              daysTillNextSchedDel
              nextScheduledDeliveryBlueTick
              nextDeliveryMauveTick
              ableToSupportBackstock
              backstockHasbeenapproved
              approvedBackstockUnits
              distFacility
              seasonalDeliveries
              straighLineMiles
              commissionRingCode
              commissionRingStatus
              nOfPRsAccountIsOn
              primaryRoute
              routeProfile
              hCIBoxes
              boxCapCF
              nOfBoxPics
              lastBoxPic
              lastBoxPrevMaint
              lastCondCleaning
              nextWorkOrderDue
              adjNormalPPUnitSales
              adjNormalPPUnits
              ppTurnRatio
              currentTurn
              cYUnits
              cYSales
              pYSales
              cYPYdiff
              pastYearInvoiceCount
              yearWddVsNormal
              adjustmentPercentage
              adjustedNormalPPUnits
              createdAt
              updatedAt
            }
            customerID
            status
            reason
            notes
            needsDelivery
            boxDown
            asap
            completed
            createdAt
            deliveryDate
            driver {
              id
              firstName
              lastName
              createdAt
              updatedAt
            }
            driverID
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      customerID
      status
      reason
      notes
      needsDelivery
      boxDown
      asap
      completed
      createdAt
      deliveryDate
      driver {
        id
        firstName
        lastName
        callins {
          items {
            id
            customer {
              id
              customerID
              customerName
              streetAddress
              city
              state
              zip
              phoneNumber
              delType
              lastInv
              lastInvAmt
              unitsDelATLastSale
              driverunitinvATLastSale
              boxCapInventoriedATLastSale
              daysSinceLastSale
              unitsScannedSinceLastDel
              boxCapScannedSinceLastSale
              lastScan
              sevenLbUnitCap
              maintainBB
              sBT
              stopComments
              nextRoutedExpRun
              daysTillNextSchedDel
              nextScheduledDeliveryBlueTick
              nextDeliveryMauveTick
              ableToSupportBackstock
              backstockHasbeenapproved
              approvedBackstockUnits
              distFacility
              seasonalDeliveries
              straighLineMiles
              commissionRingCode
              commissionRingStatus
              nOfPRsAccountIsOn
              primaryRoute
              routeProfile
              hCIBoxes
              boxCapCF
              nOfBoxPics
              lastBoxPic
              lastBoxPrevMaint
              lastCondCleaning
              nextWorkOrderDue
              adjNormalPPUnitSales
              adjNormalPPUnits
              ppTurnRatio
              currentTurn
              cYUnits
              cYSales
              pYSales
              cYPYdiff
              pastYearInvoiceCount
              yearWddVsNormal
              adjustmentPercentage
              adjustedNormalPPUnits
              createdAt
              updatedAt
            }
            customerID
            status
            reason
            notes
            needsDelivery
            boxDown
            asap
            completed
            createdAt
            deliveryDate
            driver {
              id
              firstName
              lastName
              createdAt
              updatedAt
            }
            driverID
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      driverID
      updatedAt
    }
  }
`;
export const listCallins = /* GraphQL */ `
  query ListCallins(
    $deliveryDate: AWSDate
    $statusReason: ModelCallinPrimaryCompositeKeyConditionInput
    $filter: ModelCallinFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCallins(
      deliveryDate: $deliveryDate
      statusReason: $statusReason
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        customer {
          id
          customerID
          customerName
          streetAddress
          city
          state
          zip
          phoneNumber
          location {
            lat
            lon
          }
          delType
          lastInv
          lastInvAmt
          unitsDelATLastSale
          driverunitinvATLastSale
          boxCapInventoriedATLastSale
          daysSinceLastSale
          unitsScannedSinceLastDel
          boxCapScannedSinceLastSale
          lastScan
          sevenLbUnitCap
          maintainBB
          sBT
          stopComments
          nextRoutedExpRun
          daysTillNextSchedDel
          nextScheduledDeliveryBlueTick
          nextDeliveryMauveTick
          ableToSupportBackstock
          backstockHasbeenapproved
          approvedBackstockUnits
          distFacility
          seasonalDeliveries
          straighLineMiles
          commissionRingCode
          commissionRingStatus
          nOfPRsAccountIsOn
          primaryRoute
          routeProfile
          hCIBoxes
          boxCapCF
          nOfBoxPics
          lastBoxPic
          lastBoxPrevMaint
          lastCondCleaning
          nextWorkOrderDue
          adjNormalPPUnitSales
          adjNormalPPUnits
          ppTurnRatio
          currentTurn
          cYUnits
          cYSales
          pYSales
          cYPYdiff
          pastYearInvoiceCount
          yearWddVsNormal
          adjustmentPercentage
          adjustedNormalPPUnits
          callins {
            items {
              id
              customerID
              status
              reason
              notes
              needsDelivery
              boxDown
              asap
              completed
              createdAt
              deliveryDate
              driverID
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        customerID
        status
        reason
        notes
        needsDelivery
        boxDown
        asap
        completed
        createdAt
        deliveryDate
        driver {
          id
          firstName
          lastName
          callins {
            items {
              id
              customerID
              status
              reason
              notes
              needsDelivery
              boxDown
              asap
              completed
              createdAt
              deliveryDate
              driverID
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        driverID
        updatedAt
      }
      nextToken
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      orderId
      status
      name
      createdAt
      updatedAt
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderId
        status
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      firstName
      lastName
      callins {
        items {
          id
          customer {
            id
            customerID
            customerName
            streetAddress
            city
            state
            zip
            phoneNumber
            location {
              lat
              lon
            }
            delType
            lastInv
            lastInvAmt
            unitsDelATLastSale
            driverunitinvATLastSale
            boxCapInventoriedATLastSale
            daysSinceLastSale
            unitsScannedSinceLastDel
            boxCapScannedSinceLastSale
            lastScan
            sevenLbUnitCap
            maintainBB
            sBT
            stopComments
            nextRoutedExpRun
            daysTillNextSchedDel
            nextScheduledDeliveryBlueTick
            nextDeliveryMauveTick
            ableToSupportBackstock
            backstockHasbeenapproved
            approvedBackstockUnits
            distFacility
            seasonalDeliveries
            straighLineMiles
            commissionRingCode
            commissionRingStatus
            nOfPRsAccountIsOn
            primaryRoute
            routeProfile
            hCIBoxes
            boxCapCF
            nOfBoxPics
            lastBoxPic
            lastBoxPrevMaint
            lastCondCleaning
            nextWorkOrderDue
            adjNormalPPUnitSales
            adjNormalPPUnits
            ppTurnRatio
            currentTurn
            cYUnits
            cYSales
            pYSales
            cYPYdiff
            pastYearInvoiceCount
            yearWddVsNormal
            adjustmentPercentage
            adjustedNormalPPUnits
            callins {
              nextToken
            }
            createdAt
            updatedAt
          }
          customerID
          status
          reason
          notes
          needsDelivery
          boxDown
          asap
          completed
          createdAt
          deliveryDate
          driver {
            id
            firstName
            lastName
            callins {
              nextToken
            }
            createdAt
            updatedAt
          }
          driverID
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        callins {
          items {
            id
            customer {
              id
              customerID
              customerName
              streetAddress
              city
              state
              zip
              phoneNumber
              delType
              lastInv
              lastInvAmt
              unitsDelATLastSale
              driverunitinvATLastSale
              boxCapInventoriedATLastSale
              daysSinceLastSale
              unitsScannedSinceLastDel
              boxCapScannedSinceLastSale
              lastScan
              sevenLbUnitCap
              maintainBB
              sBT
              stopComments
              nextRoutedExpRun
              daysTillNextSchedDel
              nextScheduledDeliveryBlueTick
              nextDeliveryMauveTick
              ableToSupportBackstock
              backstockHasbeenapproved
              approvedBackstockUnits
              distFacility
              seasonalDeliveries
              straighLineMiles
              commissionRingCode
              commissionRingStatus
              nOfPRsAccountIsOn
              primaryRoute
              routeProfile
              hCIBoxes
              boxCapCF
              nOfBoxPics
              lastBoxPic
              lastBoxPrevMaint
              lastCondCleaning
              nextWorkOrderDue
              adjNormalPPUnitSales
              adjNormalPPUnits
              ppTurnRatio
              currentTurn
              cYUnits
              cYSales
              pYSales
              cYPYdiff
              pastYearInvoiceCount
              yearWddVsNormal
              adjustmentPercentage
              adjustedNormalPPUnits
              createdAt
              updatedAt
            }
            customerID
            status
            reason
            notes
            needsDelivery
            boxDown
            asap
            completed
            createdAt
            deliveryDate
            driver {
              id
              firstName
              lastName
              createdAt
              updatedAt
            }
            driverID
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const customersByName = /* GraphQL */ `
  query CustomersByName(
    $customerName: String
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customersByName(
      customerName: $customerName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        customerName
        streetAddress
        city
        state
        zip
        phoneNumber
        location {
          lat
          lon
        }
        delType
        lastInv
        lastInvAmt
        unitsDelATLastSale
        driverunitinvATLastSale
        boxCapInventoriedATLastSale
        daysSinceLastSale
        unitsScannedSinceLastDel
        boxCapScannedSinceLastSale
        lastScan
        sevenLbUnitCap
        maintainBB
        sBT
        stopComments
        nextRoutedExpRun
        daysTillNextSchedDel
        nextScheduledDeliveryBlueTick
        nextDeliveryMauveTick
        ableToSupportBackstock
        backstockHasbeenapproved
        approvedBackstockUnits
        distFacility
        seasonalDeliveries
        straighLineMiles
        commissionRingCode
        commissionRingStatus
        nOfPRsAccountIsOn
        primaryRoute
        routeProfile
        hCIBoxes
        boxCapCF
        nOfBoxPics
        lastBoxPic
        lastBoxPrevMaint
        lastCondCleaning
        nextWorkOrderDue
        adjNormalPPUnitSales
        adjNormalPPUnits
        ppTurnRatio
        currentTurn
        cYUnits
        cYSales
        pYSales
        cYPYdiff
        pastYearInvoiceCount
        yearWddVsNormal
        adjustmentPercentage
        adjustedNormalPPUnits
        callins {
          items {
            id
            customer {
              id
              customerID
              customerName
              streetAddress
              city
              state
              zip
              phoneNumber
              delType
              lastInv
              lastInvAmt
              unitsDelATLastSale
              driverunitinvATLastSale
              boxCapInventoriedATLastSale
              daysSinceLastSale
              unitsScannedSinceLastDel
              boxCapScannedSinceLastSale
              lastScan
              sevenLbUnitCap
              maintainBB
              sBT
              stopComments
              nextRoutedExpRun
              daysTillNextSchedDel
              nextScheduledDeliveryBlueTick
              nextDeliveryMauveTick
              ableToSupportBackstock
              backstockHasbeenapproved
              approvedBackstockUnits
              distFacility
              seasonalDeliveries
              straighLineMiles
              commissionRingCode
              commissionRingStatus
              nOfPRsAccountIsOn
              primaryRoute
              routeProfile
              hCIBoxes
              boxCapCF
              nOfBoxPics
              lastBoxPic
              lastBoxPrevMaint
              lastCondCleaning
              nextWorkOrderDue
              adjNormalPPUnitSales
              adjNormalPPUnits
              ppTurnRatio
              currentTurn
              cYUnits
              cYSales
              pYSales
              cYPYdiff
              pastYearInvoiceCount
              yearWddVsNormal
              adjustmentPercentage
              adjustedNormalPPUnits
              createdAt
              updatedAt
            }
            customerID
            status
            reason
            notes
            needsDelivery
            boxDown
            asap
            completed
            createdAt
            deliveryDate
            driver {
              id
              firstName
              lastName
              createdAt
              updatedAt
            }
            driverID
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byCustomerIDbyDate = /* GraphQL */ `
  query ByCustomerIDbyDate(
    $customerID: ID
    $deliveryDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCallinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCustomerIDbyDate(
      customerID: $customerID
      deliveryDate: $deliveryDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer {
          id
          customerID
          customerName
          streetAddress
          city
          state
          zip
          phoneNumber
          location {
            lat
            lon
          }
          delType
          lastInv
          lastInvAmt
          unitsDelATLastSale
          driverunitinvATLastSale
          boxCapInventoriedATLastSale
          daysSinceLastSale
          unitsScannedSinceLastDel
          boxCapScannedSinceLastSale
          lastScan
          sevenLbUnitCap
          maintainBB
          sBT
          stopComments
          nextRoutedExpRun
          daysTillNextSchedDel
          nextScheduledDeliveryBlueTick
          nextDeliveryMauveTick
          ableToSupportBackstock
          backstockHasbeenapproved
          approvedBackstockUnits
          distFacility
          seasonalDeliveries
          straighLineMiles
          commissionRingCode
          commissionRingStatus
          nOfPRsAccountIsOn
          primaryRoute
          routeProfile
          hCIBoxes
          boxCapCF
          nOfBoxPics
          lastBoxPic
          lastBoxPrevMaint
          lastCondCleaning
          nextWorkOrderDue
          adjNormalPPUnitSales
          adjNormalPPUnits
          ppTurnRatio
          currentTurn
          cYUnits
          cYSales
          pYSales
          cYPYdiff
          pastYearInvoiceCount
          yearWddVsNormal
          adjustmentPercentage
          adjustedNormalPPUnits
          callins {
            items {
              id
              customerID
              status
              reason
              notes
              needsDelivery
              boxDown
              asap
              completed
              createdAt
              deliveryDate
              driverID
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        customerID
        status
        reason
        notes
        needsDelivery
        boxDown
        asap
        completed
        createdAt
        deliveryDate
        driver {
          id
          firstName
          lastName
          callins {
            items {
              id
              customerID
              status
              reason
              notes
              needsDelivery
              boxDown
              asap
              completed
              createdAt
              deliveryDate
              driverID
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        driverID
        updatedAt
      }
      nextToken
    }
  }
`;
export const byCustomerByDate = /* GraphQL */ `
  query ByCustomerByDate(
    $customerID: ID
    $deliveryDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCallinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCustomerByDate(
      customerID: $customerID
      deliveryDate: $deliveryDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer {
          id
          customerID
          customerName
          streetAddress
          city
          state
          zip
          phoneNumber
          location {
            lat
            lon
          }
          delType
          lastInv
          lastInvAmt
          unitsDelATLastSale
          driverunitinvATLastSale
          boxCapInventoriedATLastSale
          daysSinceLastSale
          unitsScannedSinceLastDel
          boxCapScannedSinceLastSale
          lastScan
          sevenLbUnitCap
          maintainBB
          sBT
          stopComments
          nextRoutedExpRun
          daysTillNextSchedDel
          nextScheduledDeliveryBlueTick
          nextDeliveryMauveTick
          ableToSupportBackstock
          backstockHasbeenapproved
          approvedBackstockUnits
          distFacility
          seasonalDeliveries
          straighLineMiles
          commissionRingCode
          commissionRingStatus
          nOfPRsAccountIsOn
          primaryRoute
          routeProfile
          hCIBoxes
          boxCapCF
          nOfBoxPics
          lastBoxPic
          lastBoxPrevMaint
          lastCondCleaning
          nextWorkOrderDue
          adjNormalPPUnitSales
          adjNormalPPUnits
          ppTurnRatio
          currentTurn
          cYUnits
          cYSales
          pYSales
          cYPYdiff
          pastYearInvoiceCount
          yearWddVsNormal
          adjustmentPercentage
          adjustedNormalPPUnits
          callins {
            items {
              id
              customerID
              status
              reason
              notes
              needsDelivery
              boxDown
              asap
              completed
              createdAt
              deliveryDate
              driverID
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        customerID
        status
        reason
        notes
        needsDelivery
        boxDown
        asap
        completed
        createdAt
        deliveryDate
        driver {
          id
          firstName
          lastName
          callins {
            items {
              id
              customerID
              status
              reason
              notes
              needsDelivery
              boxDown
              asap
              completed
              createdAt
              deliveryDate
              driverID
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        driverID
        updatedAt
      }
      nextToken
    }
  }
`;
export const byCustomerByReason = /* GraphQL */ `
  query ByCustomerByReason(
    $customerID: ID
    $reason: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCallinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCustomerByReason(
      customerID: $customerID
      reason: $reason
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer {
          id
          customerID
          customerName
          streetAddress
          city
          state
          zip
          phoneNumber
          location {
            lat
            lon
          }
          delType
          lastInv
          lastInvAmt
          unitsDelATLastSale
          driverunitinvATLastSale
          boxCapInventoriedATLastSale
          daysSinceLastSale
          unitsScannedSinceLastDel
          boxCapScannedSinceLastSale
          lastScan
          sevenLbUnitCap
          maintainBB
          sBT
          stopComments
          nextRoutedExpRun
          daysTillNextSchedDel
          nextScheduledDeliveryBlueTick
          nextDeliveryMauveTick
          ableToSupportBackstock
          backstockHasbeenapproved
          approvedBackstockUnits
          distFacility
          seasonalDeliveries
          straighLineMiles
          commissionRingCode
          commissionRingStatus
          nOfPRsAccountIsOn
          primaryRoute
          routeProfile
          hCIBoxes
          boxCapCF
          nOfBoxPics
          lastBoxPic
          lastBoxPrevMaint
          lastCondCleaning
          nextWorkOrderDue
          adjNormalPPUnitSales
          adjNormalPPUnits
          ppTurnRatio
          currentTurn
          cYUnits
          cYSales
          pYSales
          cYPYdiff
          pastYearInvoiceCount
          yearWddVsNormal
          adjustmentPercentage
          adjustedNormalPPUnits
          callins {
            items {
              id
              customerID
              status
              reason
              notes
              needsDelivery
              boxDown
              asap
              completed
              createdAt
              deliveryDate
              driverID
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        customerID
        status
        reason
        notes
        needsDelivery
        boxDown
        asap
        completed
        createdAt
        deliveryDate
        driver {
          id
          firstName
          lastName
          callins {
            items {
              id
              customerID
              status
              reason
              notes
              needsDelivery
              boxDown
              asap
              completed
              createdAt
              deliveryDate
              driverID
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        driverID
        updatedAt
      }
      nextToken
    }
  }
`;
export const callinsByStatus = /* GraphQL */ `
  query CallinsByStatus(
    $status: Status
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCallinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    callinsByStatus(
      status: $status
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer {
          id
          customerID
          customerName
          streetAddress
          city
          state
          zip
          phoneNumber
          location {
            lat
            lon
          }
          delType
          lastInv
          lastInvAmt
          unitsDelATLastSale
          driverunitinvATLastSale
          boxCapInventoriedATLastSale
          daysSinceLastSale
          unitsScannedSinceLastDel
          boxCapScannedSinceLastSale
          lastScan
          sevenLbUnitCap
          maintainBB
          sBT
          stopComments
          nextRoutedExpRun
          daysTillNextSchedDel
          nextScheduledDeliveryBlueTick
          nextDeliveryMauveTick
          ableToSupportBackstock
          backstockHasbeenapproved
          approvedBackstockUnits
          distFacility
          seasonalDeliveries
          straighLineMiles
          commissionRingCode
          commissionRingStatus
          nOfPRsAccountIsOn
          primaryRoute
          routeProfile
          hCIBoxes
          boxCapCF
          nOfBoxPics
          lastBoxPic
          lastBoxPrevMaint
          lastCondCleaning
          nextWorkOrderDue
          adjNormalPPUnitSales
          adjNormalPPUnits
          ppTurnRatio
          currentTurn
          cYUnits
          cYSales
          pYSales
          cYPYdiff
          pastYearInvoiceCount
          yearWddVsNormal
          adjustmentPercentage
          adjustedNormalPPUnits
          callins {
            items {
              id
              customerID
              status
              reason
              notes
              needsDelivery
              boxDown
              asap
              completed
              createdAt
              deliveryDate
              driverID
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        customerID
        status
        reason
        notes
        needsDelivery
        boxDown
        asap
        completed
        createdAt
        deliveryDate
        driver {
          id
          firstName
          lastName
          callins {
            items {
              id
              customerID
              status
              reason
              notes
              needsDelivery
              boxDown
              asap
              completed
              createdAt
              deliveryDate
              driverID
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        driverID
        updatedAt
      }
      nextToken
    }
  }
`;
export const callinsByReason = /* GraphQL */ `
  query CallinsByReason(
    $reason: Reason
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCallinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    callinsByReason(
      reason: $reason
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer {
          id
          customerID
          customerName
          streetAddress
          city
          state
          zip
          phoneNumber
          location {
            lat
            lon
          }
          delType
          lastInv
          lastInvAmt
          unitsDelATLastSale
          driverunitinvATLastSale
          boxCapInventoriedATLastSale
          daysSinceLastSale
          unitsScannedSinceLastDel
          boxCapScannedSinceLastSale
          lastScan
          sevenLbUnitCap
          maintainBB
          sBT
          stopComments
          nextRoutedExpRun
          daysTillNextSchedDel
          nextScheduledDeliveryBlueTick
          nextDeliveryMauveTick
          ableToSupportBackstock
          backstockHasbeenapproved
          approvedBackstockUnits
          distFacility
          seasonalDeliveries
          straighLineMiles
          commissionRingCode
          commissionRingStatus
          nOfPRsAccountIsOn
          primaryRoute
          routeProfile
          hCIBoxes
          boxCapCF
          nOfBoxPics
          lastBoxPic
          lastBoxPrevMaint
          lastCondCleaning
          nextWorkOrderDue
          adjNormalPPUnitSales
          adjNormalPPUnits
          ppTurnRatio
          currentTurn
          cYUnits
          cYSales
          pYSales
          cYPYdiff
          pastYearInvoiceCount
          yearWddVsNormal
          adjustmentPercentage
          adjustedNormalPPUnits
          callins {
            items {
              id
              customerID
              status
              reason
              notes
              needsDelivery
              boxDown
              asap
              completed
              createdAt
              deliveryDate
              driverID
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        customerID
        status
        reason
        notes
        needsDelivery
        boxDown
        asap
        completed
        createdAt
        deliveryDate
        driver {
          id
          firstName
          lastName
          callins {
            items {
              id
              customerID
              status
              reason
              notes
              needsDelivery
              boxDown
              asap
              completed
              createdAt
              deliveryDate
              driverID
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        driverID
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        blogID
        tags
        createdAt
        updatedAt
        blog {
          id
          name
          createdAt
          updatedAt
          posts {
            items {
              id
              title
              blogID
              tags
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
        comments {
          items {
            id
            postID
            content
            createdAt
            updatedAt
            post {
              id
              title
              blogID
              tags
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      title
      blogID
      tags
      createdAt
      updatedAt
      blog {
        id
        name
        createdAt
        updatedAt
        posts {
          items {
            id
            title
            blogID
            tags
            createdAt
            updatedAt
            blog {
              id
              name
              createdAt
              updatedAt
            }
            comments {
              nextToken
            }
            owner
          }
          nextToken
        }
      }
      comments {
        items {
          id
          postID
          content
          createdAt
          updatedAt
          post {
            id
            title
            blogID
            tags
            createdAt
            updatedAt
            blog {
              id
              name
              createdAt
              updatedAt
            }
            comments {
              nextToken
            }
            owner
          }
        }
        nextToken
      }
      owner
    }
  }
`;
