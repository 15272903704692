import React, { useReducer, useEffect, useState } from 'react';
import './App.css';
import { API, graphqlOperation } from 'aws-amplify';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { listNotes, byCustomerByDate, listCallins } from './graphql/queries';
import { createNote as createNoteMutation, deleteNote as deleteNoteMutation } from './graphql/mutations';
import DeckGL from '@deck.gl/react';
import { LineLayer } from '@deck.gl/layers';
import { StaticMap } from 'react-map-gl';

///    // Set your mapbox access token here
//    const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoibWF0aGV3czIyNSIsImEiOiJja292dDZzdnAwMGluMm9vOWV4MWlkdHoxIn0.X5hZ4_XX4k5HAjbYg13pvQ';
//
//    // Viewport settings
//    const INITIAL_VIEW_STATE = {
//      longitude: -100.41669,
//      latitude: 37.7853,
//      zoom: 13,
//      pitch: 0,
//      bearing: 0
//    };
//
//    // Data to be used by the LineLayer
//    const data = [
//      { sourcePosition: [-122.41669, 37.7853], targetPosition: [-122.41669, 37.781] }
//    ];
//
//    const today = new Date().toISOString()
//
//    const initialFormState = {
//      customerID: ''
//      , reason: ''
//      , notes: ''
//      , needsDelivery: ''
//      , boxDown: ''
//      , asap: ''
//      , deliveryDate: ''
//    }
//
//    // create reducer to update state
//    function reducer(state, action) {
//      switch (action.type) {
//        case 'SET_CALLINS':
//          return { ...state, callins: action.callins }
//          break;
//        case 'SET_INPUT':
//          return { ...state, [action.key]: action.value }
//          break;
//        case 'CLEAR_INPUT':
//          return { ...initialFormState, callins: state.callins }
//          break;
//        default:
//          return state
//          break;
//      }
//    }
//
//    const layers = [
//      new LineLayer({ id: 'line-layer', data })
//    ];
// https://www.youtube.com/watch?v=HZUlQ7Z2xHQ&t=2700s
function App() {

  const [callins, setCallins] = useState([])
  //
  //      const [state, dispatch] = useReducer(reducer, initialFormState)
  //
  useEffect(() => {
    getData()
  }, [])


  async function getData() {
    try {
      const callInData = await API.graphql(graphqlOperation(listCallins))
      console.log('callins data from API: ', callInData);
      setCallins(callInData.listCallins.items)
      //dispatch({ type: 'SET_CALLINS', callInData.byCustomerByDate.items})
    } catch (error) {
      console.log('ERROR 71: Failed to load Callins', error);
    }
  }





  /*
  
  
  function App() {
    useEffect(() => {
      fetchCallins();
    }, []);
  
    async function fetchCallins({ initialLoadValues }) {
      const apiData = await API.graphql({ query: byCustomerByDate, filter: { and: { completed: { ne: true }, deliveryDate: { le: initialLoadValues.date } } } });
      setCallins(apiData.data.byCustomerByDate.items);
    }
  
  
  
    
  
  
    /* example for notes app /}
  
    const [notes, setNotes] = useState([]);
    const [formData, setFormData] = useState(initialFormState);
  
    useEffect(() => {
      fetchNotes();
    }, []);
  
    async function fetchNotes() {
      const apiData = await API.graphql({ query: listNotes });
      setNotes(apiData.data.listNotes.items);
    }
  
    async function createNote() {
      if (!formData.name || !formData.description) return;
      await API.graphql({ query: createNoteMutation, variables: { input: formData } });
      setNotes([ ...notes, formData ]);
      setFormData(initialFormState);
    }
  
    async function deleteNote({ id }) {
      const newNotesArray = notes.filter(note => note.id !== id);
      setNotes(newNotesArray);
      await API.graphql({ query: deleteNoteMutation, variables: { input: { id } }});
    }
  */
  return (



    <div className="App">
      <div className="mapContainer">
        <div className="vp9090">
          {/*
          <DeckGL
            initialViewState={INITIAL_VIEW_STATE}
            controller={true}
            layers={layers}
          >
            <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} />
          </DeckGL>

          
          <h1>My Notes App</h1>
          <input
            onChange={e => setFormData({ ...formData, 'name': e.target.value})}
            placeholder="Note name"
            value={formData.name}
          />
          <input
            onChange={e => setFormData({ ...formData, 'description': e.target.value})}
            placeholder="Note description"
            value={formData.description}
          />
          <button onClick={createNote}>Create Note</button>
          <div style={{marginBottom: 30}}>
            {
              notes.map(note => (
                <div key={note.id || note.name}>
                  <h2>{note.name}</h2>
                  <p>{note.description}</p>
                  <button onClick={() => deleteNote(note)}>Delete note</button>
                </div>
              ))
            }
          </div>

    */}

        </div>
      </div>
      <AmplifySignOut />
    </div>
  );
}

export default withAuthenticator(App);